import TypingEffect from "../TypingEffect";

const XtremeMain = () => {
  return (
    <section id="home" className="relative overflow-hidden gradient bg-primary">
      {/* Background Video */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover z-0 brightness-[70%]"
        autoPlay
        loop
        muted
      >
        <source
          src="https://firebasestorage.googleapis.com/v0/b/bookymet.firebasestorage.app/o/demo%2Fbgvid.mp4?alt=media&token=7915f1a0-25be-4ceb-b756-1d4481dc457e"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      {/* Overlay (Optional: Adds a subtle gradient over the video) */}
      {/* <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30 z-10"></div> */}

      {/* Content */}
      <div className="relative z-20 container md:py-0">
        <div className="flex flex-col md:flex-row">
          <div className="w-full h-[calc(100vh-80px)] md:w-1/2 text-white flex flex-col justify-center lg:py-0">
            <h1 className="font-heading mb-5">
              <span className="text-5xl md:text-4xl block mb-2">
                <span className="block mb-1 xs:inline xs:mt-0">
                  Welcome to Climax
                </span>
                <br />
                <TypingEffect
                  text="Intelligence Xtreme"
                  speed={200}
                  pauseTime={1000}
                  className="text-tertary md:text-6xl font-bold"
                />
              </span>
            </h1>
            <p className="text-pretty font-normal text-sm md:text-base lg:text-[15px] font-body px-px leading-[20px]">
              At Climax Intelligence Xtreme, we’re more than just a solutions
              provider – we’re your partner in innovation, sustainability, and
              technical excellence. Built on the ethos of delivering ‘Glocalized
              Solutions,’ we bring the perfect balance of global expertise and
              local insight to help businesses thrive in an ever-changing world.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default XtremeMain;
