import AboutUs from "../components/about/AboutUs";
import AboutMain from "../components/about/AboutMain";

const About = () => {
  return (
    <>
      <AboutMain />
      <AboutUs />
    </>
  );
};

export default About;
