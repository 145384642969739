import About from "../components/home/About";
import Contact from "../components/home/Contact";
import HomeMain from "../components/home/HomeMain";

const Home = () => {
  return (
    <>
      <HomeMain />
      <About />
      <Contact />
    </>
  );
};

export default Home;
