import MerchMain from "../components/merch/MerchMain";

const Merchandise = () => {
  return (
    <>
      <MerchMain />
    </>
  );
};

export default Merchandise;
