import Form from "../components/contact/Form";
import ContactMain from "../components/contact/ContactMain";

const Contact = () => {
  return (
    <>
      <ContactMain />
      <Form />
    </>
  );
};

export default Contact;
