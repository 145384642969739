import XtremeMain from "../components/xtreme/XtremeMain";
import Xtremes from "../components/xtreme/Xtremes";

const Xtreme = () => {
  return (
    <>
      <XtremeMain />
      <Xtremes />
    </>
  );
};

export default Xtreme;
