import ModuleMain from "../components/modules/ModuleMain";
import Module from "../components/modules/Module";

const Modules = () => {
  return (
    <>
      <ModuleMain />
      <Module />
    </>
  );
};

export default Modules;
