import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const AboutUs = () => {
  const [image, setImage] = useState([
    "https://i.ibb.co/rF25Lxr/about1.jpg",
    "https://i.ibb.co/8833HNy/about2.jpg",
    "https://i.ibb.co/nM13M8T/about3.png",
    "https://i.ibb.co/cJvMh8q/about4.png",
  ]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const newImages = [
        [
          "https://i.ibb.co/cJvMh8q/about4.png",
          "https://i.ibb.co/rF25Lxr/about1.jpg",
          "https://i.ibb.co/8833HNy/about2.jpg",
          "https://i.ibb.co/nM13M8T/about3.png",
        ],
        [
          "https://i.ibb.co/nM13M8T/about3.png",
          "https://i.ibb.co/cJvMh8q/about4.png",
          "https://i.ibb.co/rF25Lxr/about1.jpg",
          "https://i.ibb.co/8833HNy/about2.jpg",
        ],
        [
          "https://i.ibb.co/8833HNy/about2.jpg",
          "https://i.ibb.co/nM13M8T/about3.png",
          "https://i.ibb.co/cJvMh8q/about4.png",
          "https://i.ibb.co/rF25Lxr/about1.jpg",
        ],
        [
          "https://i.ibb.co/rF25Lxr/about1.jpg",
          "https://i.ibb.co/8833HNy/about2.jpg",
          "https://i.ibb.co/nM13M8T/about3.png",
          "https://i.ibb.co/cJvMh8q/about4.png",
        ],
      ];

      setImage(newImages[count % 4]);
      setCount((prev) => prev + 1);
    }, 8000);

    return () => clearInterval(interval);
  }, [count]);

  return (
    <section id="about" className="py-10 overflow-x-hidden mt-10">
      <div className="container">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2">
            <div className="border-l-4 border-tertary px-5 mb-4">
              <h4 className="font-body text-[10px]">
                Ignite Your Path to Excellence with Climax
              </h4>
              <h1 className="text-5xl text-secondary font-heading font-medium">
                About Us
              </h1>
            </div>
            <p className="font-body font-normal text-sm md:text-base lg:text-lg text-balance leading-[22px]">
              Climax was founded on the principle that communication is key to
              success. From students of various domains to professionals in the
              corporate world, we are dedicated to ensuring you excel in
              expressing yourself with clarity, precision, and confidence. Our
              comprehensive modules are designed to cater to a wide spectrum of
              needs, helping you achieve personal and professional growth.
            </p>
            <div>
              <h4 className="font-medium font-heading py-2 text-2xl">
                What we focus?
              </h4>
              <p className="font-body font-normal text-sm md:text-base lg:text-lg text-balance leading-[22px]">
                At Climax, we don&apos;t just focus on words; we focus on the
                art of delivering them. Whether it&apos;s mastering vocabulary,
                refining your accent, or developing the leadership presence
                required for C-level roles, our courses are designed to make you
                stand out.
              </p>
            </div>
          </div>
          <div className="w-full flex justify-center mt-10 md:mt-0 md:w-1/2">
            <div className="flex gap-6 md:gap-8">
              <div className="flex flex-col justify-start gap-8 mb-5 md:mb-0">
                <motion.img
                  className="shadow-lg noselect"
                  key={`${image[0]}-${count}`}
                  src={image[0]}
                  initial={{ y: 70, scale: 0.7 }}
                  animate={{ y: 0, scale: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  alt="About Image 4"
                />
                <motion.img
                  className="shadow-lg noselect"
                  key={`${image[3]}-${count}`}
                  src={image[3]}
                  initial={{ y: 70, translateX: 50, scale: 0.7 }}
                  animate={{ y: 0, translateX: 0, scale: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  alt="About Image 1"
                />
              </div>
              <div className="flex flex-col justify-end gap-6 md:gap-8 mt-5 md:mt-0">
                <motion.img
                  className="shadow-lg noselect"
                  key={`${image[1]}-${count}`}
                  src={image[1]}
                  initial={{ y: -70, translateX: -50, scale: 0.7 }}
                  animate={{ y: 0, translateX: 0, scale: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  alt="About Image 2"
                />
                <motion.img
                  className="shadow-lg noselect"
                  key={`${image[2]}-${count}`}
                  src={image[2]}
                  initial={{ y: -70, scale: 0.7 }}
                  animate={{ y: 0, scale: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  alt="About Image 3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
