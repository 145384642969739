/* eslint-disable react/no-unescaped-entities */
import { useState, useEffect } from "react";
import TypingEffect from "../TypingEffect";

const images = [
  "https://i.ibb.co/2ydM6r3/bg1.jpg",
  "https://i.ibb.co/DRf0RKw/bg2.jpg",
  "https://i.ibb.co/0cQcjPc/bg3.jpg",
  "https://i.ibb.co/GQyvxGq/bg4.jpg",
  "https://i.ibb.co/r6cxH11/bg5.jpg",
];

const MerchMain = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <section id="home" className="overflow-x-hidden gradient relative">
      {/* Background Image */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full bg-cover bg-center brightness-50 transition-opacity duration-1000 ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>

      {/* Content */}
      <div className="container h-[calc(100vh-80px)] flex justify-center items-center relative z-10">
        <div className="w-full flex flex-col justify-center items-center text-center py-12 md:py-5 lg:py-0">
          <h1 className="w-[90vw] md:w-[700px] font-heading mb-5">
            <span className="text-5xl text-white md:text-6xl block mb-2">
              <span className="block mb-1 xs:inline xs:mt-0">
                Welcome to the World of
              </span>{" "}
              <TypingEffect
                text="Climax Merchandise"
                speed={200}
                pauseTime={1000}
                className="text-tertary flex-wrap font-bold"
              />
            </span>
            <span className="text-4xl md:text-5xl"></span>
          </h1>
          <p className="w-full md:w-[1000px] text-gray-400 text-pretty font-normal text-sm md:text-base lg:text-[15px] font-body leading-[22px]">
            Get ready to{" "}
            <b className="agu-bold text-white text-[23px]">
              redefine your style and showcase your personality
            </b>{" "}
            like never before! At Climax, we believe in empowering individuals
            to shine, and now, we're bringing that vision to life through our
            exclusive merchandise. From premium apparel that speaks confidence
            to accessories that inspire creativity, our collection is designed
            for trailblazers like you.{" "}
            <b className="handlee-extrabold text-white text-[23px]">
              Every piece is a statement, a celebration of individuality, and a
              testament to the vibrant community we’re building together.
            </b>
          </p>
        </div>
      </div>
    </section>
  );
};

export default MerchMain;
