const ModuleMain = () => {
  return (
    <>
      <div className="w-full h-auto lg:h-[calc(100vh-80px)] -top-10 md:-top-32 lg:top-0 overflow-hidden relative flex justify-center items-center">
        <img
          src={"https://i.ibb.co/dm2JMXX/modules.jpg"}
          className="brightness-[70%] w-full"
          alt=""
        />
        <p className="absolute text-white font-semibold text-[70px] lg:text-[120px]">
          Our Modules
        </p>
      </div>
    </>
  );
};

export default ModuleMain;
