import { useState } from "react";
import { FaMapMarkedAlt } from "react-icons/fa";
import Send from "../../assets/send.svg";
import { MdEmail, MdWifiCalling3 } from "react-icons/md";

const Form = () => {
  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;
    setFormDetails((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { firstName, email, phoneNumber, message } = formDetails;
    if (!firstName || !email || !phoneNumber || !message) {
      alert("Fill all the required fields");
      return;
    }

    const formData = new FormData(event.currentTarget);

    formData.append("access_key", "a0b6abd1-0437-4a3d-9a81-839931a6af41");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });
      const result = await res.json();

      if (result.success) {
        console.log("Success", result);
        setFormDetails({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
        alert(result.success.message);
      }
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:theclxcommunity@gmail.com";
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+916369812768";
  };

  const handleAddressClick = () => {
    window.open(
      `https://www.google.com/maps/place/Unihomes+Phase+2/@12.8367771,80.1283457,17z/data=!3m1!4b1!4m6!3m5!1s0x3a5259bd375d0ec1:0x9228872784e98694!8m2!3d12.8367771!4d80.1283457!16s%2Fg%2F11pfcyys4t?entry=ttu&g_ep=EgoyMDI0MTExOC4wIKXMDSoASAFQAw%3D%3D`,
      "_blank"
    );
  };

  return (
    <section id="form" className="overflow-x-hidden py-10 mt-10">
      <div className="container">
        <div className="flex flex-col gap-10 md:flex-row">
          {/* Form details */}
          <div className="w-full md:w-5/12">
            <div className="border-l-4 border-tertary px-5 mb-4">
              <h4 className="font-body text-[10px]">
                Let us help you unlock your potential!
              </h4>
              <h1 className="text-5xl text-secondary font-heading font-medium">
                Contact Us
              </h1>
            </div>
            <p className="font-body font-normal text-sm md:text-base lg:text-lg leading-[22px]">
              For inquiries, program details, or to enroll in one of our
              modules, feel free to reach out. Our team is ready to assist you
              in taking the next step toward personal and professional growth.
            </p>
            <div className="mt-8 space-y-5">
              <div
                onClick={handleEmailClick}
                className="flex items-start gap-5 cursor-pointer"
              >
                <div className="bg-tertary/30 p-3 rounded-md shadow-md">
                  <MdEmail size={28} className="text-secondary" />
                </div>
                <div>
                  <span className="block text-lg font-semibold font-heading">
                    Email:
                  </span>
                  <span className="font-body text-sm font-medium text-slate-600">
                    theclxcommunity@gmail.com
                  </span>
                </div>
              </div>
              <div
                onClick={handlePhoneClick}
                className="flex items-start gap-5 cursor-pointer"
              >
                <div className="bg-tertary/30 p-3 rounded-md shadow-md">
                  <MdWifiCalling3 size={28} className="text-secondary" />
                </div>
                <div>
                  <span className="block text-lg font-semibold font-heading">
                    Phone:
                  </span>
                  <span className="font-body text-sm font-medium text-slate-600">
                    +91 63-6981-278
                  </span>
                </div>
              </div>
              <div
                onClick={handleAddressClick}
                className="flex items-start gap-5 cursor-pointer"
              >
                <div className="bg-tertary/30 p-3 rounded-md shadow-md">
                  <FaMapMarkedAlt size={28} className="text-secondary" />
                </div>
                <div>
                  <span className="block text-lg font-semibold font-heading">
                    Address:
                  </span>
                  <p className="font-body text-sm font-medium text-slate-600 leading-5">
                    Unihomes 2, Phase 2, Nallambakkam, Rattinamangalam, Tamil
                    Nadu - 600127.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Form */}
          <form
            onSubmit={handleFormSubmit}
            className="w-full md:w-7/12 shadow-sm border-[1.5px] border-green-700 border-opacity-10 flex flex-col items p-5 rounded-lg"
          >
            <div className="flex flex-col gap-6">
              <div className="w-full space-x-6 flex justify-between items-start">
                <input
                  type="text"
                  name="firstName"
                  value={formDetails.firstName}
                  onChange={handleChange}
                  className="bg-gray-200 text-green-950 text-[16px] focus:outline-none py-4 px-6 rounded-xl w-full"
                  placeholder="First name"
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  value={formDetails.lastName}
                  onChange={handleChange}
                  className="bg-gray-200 text-green-950 text-[16px] focus:outline-none py-4 px-6 rounded-xl w-full"
                  placeholder="Last name"
                />
              </div>
              <div className="w-full space-x-6 flex justify-between items-start">
                <input
                  type="email"
                  name="email"
                  value={formDetails.email}
                  onChange={handleChange}
                  className="bg-gray-200 text-green-950 text-[16px] focus:outline-none py-4 px-6 rounded-xl w-full"
                  placeholder="Email"
                  required
                />
                <input
                  type="text"
                  name="phoneNumber"
                  value={formDetails.phoneNumber}
                  onChange={handleChange}
                  className="bg-gray-200 text-green-950 text-[16px] focus:outline-none py-4 px-6 rounded-xl w-full"
                  placeholder="Phone No"
                  required
                />
              </div>
            </div>
            <textarea
              className="bg-gray-200 text-green-950 text-[16px] focus:outline-none py-4 px-6 rounded-xl w-full resize-none my-6 h-32 md:h-full"
              placeholder="Write your message"
              name="message"
              value={formDetails.message}
              onChange={handleChange}
              required
            />
            <div className="w-full flex justify-end items-end">
              <button
                type="submit"
                className="w-fit bg-tertary/40 right-0 font-medium pl-5 pr-2 py-2.5 text-secondary rounded-[30px] hover:bg-opacity-90 transition-colors flex items-center gap-2"
              >
                Send message
                <span className="w-10 h-10 flex justify-center items-center bg-secondary rounded-full">
                  <img src={Send} className="rotate-45 w-6 -ml-1.5" />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Form;
