// import { IoIosCheckbox } from "react-icons/io";

const Xtremes = () => {
  // const technicalSol = [
  //   "IT Infrastructure Services",
  //   "Platform as a Service (PaaS)",
  //   "Cloud Engineering",
  //   "SWOT Analysis and Business Consultation",
  // ];

  const contents = [
    {
      title: "Our Mission",
      description:
        "To empower organizations with cutting-edge solutions, enabling sustainable growth, seamless operations, and unparalleled market presence.",
    },
    {
      title: "What We Do",
      description:
        "From crafting robust IT infrastructures to executing highly effective digital strategies, Climax Intelligence Xtreme is your one-stop hub for professional and technical solutions.",
    },
    {
      title: "Our Expertise Includes",
      description: (
        <>
          <ul className="mt-6">
            <li className="text-gray-600 text-[16px] mt-4">
              <strong className="text-primary text-[20px] font-semibold">
                IT Infrastructure & Cloud Engineering:
              </strong>{" "}
              Streamline your business with tailor-made IT frameworks and
              scalable cloud solutions. We design, deploy, and manage IT
              ecosystems that enhance efficiency and ensure future-proof
              operations.
            </li>
            <li className="text-gray-600 text-[16px] mt-4">
              <strong className="text-primary text-[20px] font-semibold">
                Platform-as-a-Service (PaaS):
              </strong>{" "}
              Experience agility like never before. Our PaaS offerings empower
              developers and businesses to build, deploy, and scale applications
              without worrying about the backend.
            </li>
            <li className="text-gray-600 text-[16px] mt-4">
              <strong className="text-primary text-[20px] font-semibold">
                Sustainable Development Solutions:
              </strong>{" "}
              Embrace green technology with sustainability-driven strategies
              that integrate environmental, social, and governance (ESG) factors
              into your operations.
            </li>
            <li className="text-gray-600 text-[16px] mt-4">
              <strong className="text-primary text-[20px] font-semibold">
                SWOT Analysis & Strategic Consulting:
              </strong>{" "}
              Make informed decisions with comprehensive analyses and insights
              tailored to your unique business challenges and goals.
            </li>
            <li className="text-gray-600 text-[16px] mt-4">
              <strong className="text-primary text-[20px] font-semibold">
                Digital Marketing Services:
              </strong>{" "}
              Elevate your brand’s presence with data-driven marketing
              strategies. From social media campaigns to SEO, performance
              marketing, and content creation, we ensure your brand stands out
              in the digital space.
            </li>
            <li className="text-gray-600 text-[16px] mt-4">
              <strong className="text-primary text-[20px] font-semibold">
                Technical Solutions & Customization:
              </strong>{" "}
              Whether it’s automation, AI-driven tools, or industry-specific
              software, our technical services are designed to meet your precise
              needs.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Why Choose Us?",
      description:
        "At Climax Intelligence Xtreme, we don’t just provide solutions; we create opportunities for innovation and growth. Every service we offer is meticulously crafted to align with your objectives, helping you stay ahead in a competitive world.",
    },
    {
      title: "The Future is Now",
      description:
        "Whether you're scaling up or reimagining your processes, Climax Intelligence Xtreme is here to make your vision a reality. Join us in shaping a sustainable, intelligent, and digitally empowered tomorrow.",
    },
  ];

  return (
    <>
      {/* <section id="xtreme" className="overflow-x-hidden py-10 mt-10">
        <div className="container">
          <div className="border-l-4 border-tertary px-5 mb-4">
            <h4 className="font-body text-[10px]">
              Innovating Today for a Sustainable Tomorrow
            </h4>
            <h1 className="text-5xl text-secondary font-heading font-medium">
              Intelligence Xtreme
            </h1>
          </div>
          <div className="flex flex-col gap-10 md:flex-row">
            <div className="w-full md:w-7/12 space-y-5">
              <p className="font-body font-normal text-sm md:text-base lg:text-lg leading-[22px]">
                At{" "}
                <span className="font-semibold">
                  Climax Intelligence Xtreme
                </span>
                , we are driven by the mission to address local and global
                challenges with innovative &quot;Glocalized solutions&quot;. Our
                focus lies in sustainable development, advancing technological
                innovation while adhering to the highest ethical standards. We
                strive to create solutions that protect the environment and
                human values while fostering growth.
              </p>
              <ul className="font-body font-normal text-sm md:text-base text-balance leading-[22px] space-y-2">
                <h3 className="font-medium font-heading text-lg leading-5 mb-5 md:text-2xl">
                  Under our Professional Services Domain, we offer an array of
                  technical solutions, including:
                </h3>
                {technicalSol.map((item, i) => (
                  <li key={i}>
                    <IoIosCheckbox
                      size={18}
                      className="inline mr-2 text-secondary/80"
                    />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-5/12">
              <div>
                <img className="size-full object-cover noselect" src={"https://i.ibb.co/WvScDNr/xstrem1.png"} />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Other Topics and Their Contents */}
      <div className="flex flex-col gap-y-6 my-20">
        {contents.map((content, index) => (
          <div
            key={index}
            className="max-w-[90vw] lg:max-w-[900px] mx-auto border-b-[2px] py-14 border-opacity-5 border-secondary rounded-[5px]"
          >
            <div className="border-l-4 border-tertary px-5 mb-4">
              <h1 className="text-5xl text-secondary font-heading font-medium">
                {content.title}
              </h1>
            </div>
            <p className="font-body font-normal text-sm md:text-base lg:text-lg leading-[22px]">
              {content.description}
            </p>
          </div>
        ))}
        <div className="max-w-[90vw] lg:w-[900px] mx-auto mt-20">
          <h1 className="text-[25px] text-opacity-75 text-center text-secondary handlee-extrabold">
            Let’s transform your business together – one solution at a time.
          </h1>
          <div className="border-l-4 border-tertary px-5 mb-4 mt-20">
            <h1 className="text-5xl font-heading font-medium text-start text-secondary">
              Ready to explore the possibilities?
              <p className="font-body font-normal text-sm mt-2 text-black md:text-base lg:text-md leading-[22px]">
                journey toward excellence with Climax Intelligence Xtreme.
                Contact us today and embark on a
              </p>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Xtremes;
