import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CgClose, CgMenu } from "react-icons/cg";
import logo from "../assets/Climax.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // for programmatic navigation

  const nav = [
    { name: "Home", link: "/" },
    { name: "About", link: "about", isScroll: true, isHomeOnly: true },
    { name: "Modules", link: "/modules" },
    { name: "Xtreme", link: "/xtreme" },
    { name: "Our Merch", link: "/merchandise" },
  ];

  const handleContactClick = () => {
    // Navigate to the home page and then scroll to the contact section
    navigate("/", { replace: true });
    setTimeout(() => {
      const contactSection = document.getElementById("contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 50); // Small timeout to ensure navigation happens first
  };

  return (
    <nav className="shadow-md fixed z-50 top-0 left-0 w-full bg-white">
      <div className="max-w-full mx-auto px-6 lg:px-6">
        <div className="flex justify-between h-16 lg:h-20">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center">
            <Link
              to="/"
              className="size-12 md:size-16 flex items-center cursor-pointer"
            >
              <img
                src={logo}
                className="h-full w-full object-cover"
                alt="The Climax"
              />
              <p className="text-[#224d5b] font-bold text-[35px] ml-1.5">
                Climax
              </p>
            </Link>
          </div>

          {/* Links - Desktop view */}
          <div className="hidden lg:flex items-center gap-10 ml-10">
            {nav.map(
              (item) =>
                // Render "About" link only on the home page
                (!item.isHomeOnly || location.pathname === "/") &&
                (item.isScroll ? (
                  <ScrollLink
                    key={item.name}
                    to={item.link}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="link-dt cursor-pointer"
                  >
                    {item.name}
                  </ScrollLink>
                ) : (
                  <Link
                    key={item.name}
                    to={item.link}
                    className={`link-dt ${
                      location.pathname === item.link ? "link-dt-active" : ""
                    }`}
                  >
                    {item.name}
                  </Link>
                ))
            )}
          </div>

          {/* Contact Button */}
          <div className="hidden lg:flex items-center">
            <button
              onClick={handleContactClick}
              className="bg-[#224d5b] font-medium px-5 py-3 text-white rounded-[30px] hover:bg-opacity-90 transition-colors"
            >
              Contact us
            </button>
          </div>

          {/* Ham */}
          <div className="flex lg:hidden items-center gap-4">
            <button onClick={() => setIsOpen(true)}>
              <CgMenu size={25} />
            </button>
          </div>

          {/* The great Nothing to close mobile nav */}
          {isOpen && (
            <div
              onClick={() => setIsOpen(false)}
              className="fixed top-0 left-0 w-1/4 h-full bg-transparent"
            />
          )}

          {/* Links - Mobile view */}
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0.5, x: 700 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0.5, x: 700 }}
                transition={{ duration: 0.5 }}
                className="lg:hidden fixed top-0 right-0 w-3/4 h-full bg-white"
              >
                <button
                  onClick={() => setIsOpen(false)}
                  className="absolute top-6 right-6"
                >
                  <CgClose size={30} />
                </button>
                <div className="flex flex-col justify-center gap-5 mt-24 px-6">
                  {nav.map(
                    (item) =>
                      // Render "About" link only on the home page
                      (!item.isHomeOnly || location.pathname === "/") &&
                      (item.isScroll ? (
                        <ScrollLink
                          key={item.name}
                          to={item.link}
                          smooth={true}
                          duration={500}
                          offset={-70}
                          className="link-dt cursor-pointer"
                        >
                          {item.name}
                        </ScrollLink>
                      ) : (
                        <Link
                          key={item.name}
                          to={item.link}
                          className={`link-dt ${
                            location.pathname === item.link
                              ? "link-dt-active"
                              : ""
                          }`}
                        >
                          {item.name}
                        </Link>
                      ))
                  )}
                </div>

                <div className="mt-8 ml-5">
                  <button
                    onClick={handleContactClick}
                    className="bg-[#224d5b] font-body font-medium px-5 py-3 text-white rounded-[30px] hover:bg-opacity-90"
                  >
                    Contact us
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
