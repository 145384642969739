import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Router from "./router/Router";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const App = () => {
  const location = useLocation();
  const hideFooterPaths = ["/merchandise"];

  // Scroll to top on location change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Navbar />
      <div className="mt-20">
        <Router />
      </div>
      {!hideFooterPaths.includes(location.pathname) && <Footer />}
    </>
  );
};

export default App;
