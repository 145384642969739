import TypingEffect from "../TypingEffect";

const HomeMain = () => {
  return (
    <section id="home" className="overflow-x-hidden gradient">
      <div className="container md:py-0">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 flex flex-col justify-center py-12 md:py-5 lg:py-0">
            <h1 className="font-heading mb-5">
              <span className="text-5xl md:text-6xl block mb-2">
                <span className="block mb-1 xs:inline xs:mt-0">Welcome to</span>{" "}
                <TypingEffect
                  text="Climax"
                  speed={200}
                  pauseTime={1000}
                  className="text-secondary font-bold"
                />
              </span>
              <span className="text-4xl md:text-5xl">
                Unleashing Your Potential
              </span>
            </h1>
            <p className="text-pretty font-normal text-sm md:text-base lg:text-lg font-body px-px leading-[22px]">
              At Climax, we believe in empowering individuals to shine with
              exceptional communication and personality development. Whether
              you&apos;re a student seeking to sharpen your skills or a
              corporate leader aiming to command the room, we offer
              transformative programs tailored to unlock your potential. Our
              expertly designed modules cater to individuals of all levels,
              helping you conquer the art of communication and embody confidence
              like never before.
            </p>
          </div>

          <div className="w-full md:w-1/2 relative flex justify-center">
            <div className="absolute top-5 left-[50%] translate-x-[-50%] lg:top-16 size-64 md:size-96 lg:size-[520px] bg-orange-400/60 rounded-full -z-10" />
            <div className="lg:h-[100vh]">
              <img
                src={"https://i.ibb.co/HdTVqyK/hero-img.png"}
                className="object-cover md:object-contain size-full drop-shadow-lg noselect"
                alt="hero-img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeMain;
